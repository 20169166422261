import React from "react"
import { Link } from "gatsby"
import "@fortawesome/fontawesome-free/js/all"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Contact from "../components/Contact"
import "../styles/index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Services />
    <Contact />
  </Layout>
)

export default IndexPage
