import React from "react"
import hero from "../images/hero.svg"

const Hero = () => {
  return (
    <div
      className="bg-local bg-no-repeat bg-center h-192 bg-opacity-75 bg-gray-200"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <div className={"flex flex-row items-center pt-40 container mx-auto"}>
        <div className="w-full md:w-5/12 px-4">
          <div className="flex-column items-center md:pr-12">
            <div className="flex items-center">
              <div className="text-blue-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 mr-6 shadow-lg rounded-full bg-blue-300">
                <i className="fas fa-rocket text-3xl"></i>
              </div>
              <h3 className="text-gray-800 text-4xl font-semibold mb-6">
                Launch Your Idea
              </h3>
            </div>
            <p className="mt-4 text-lg leading-relaxed text-gray-700">
              NuZETA is your one stop App development shop to launch your
              project. We specialize in:
            </p>
            <ul className="list-none mt-6">
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-flex  w-8 h-8 items-center justify-center rounded-full text-blue-700 bg-blue-200 mr-3 shadow-lg">
                      <i className="fas fa-window-maximize"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-700">Web Apps</h4>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-flex  w-8 h-8 items-center justify-center rounded-full text-blue-700 bg-blue-200 mr-3 shadow-lg">
                      <i className="fas fa-mobile-alt"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-700">Mobile Apps</h4>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-flex  w-8 h-8 items-center justify-center rounded-full text-blue-700 bg-blue-200 mr-3 shadow-lg">
                      <i className="fas fa-cloud"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-700">
                      Cloud Setup and Operations
                    </h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <a
            href="#contact"
            className="bg-blue-700 text-white active:bg-gray-700 text-sm font-bold mt-6 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            style={{ transition: "all .15s ease" }}
          >
            Build Yours
          </a>
        </div>
      </div>
    </div>
  )
}

export default Hero
