import React from "react"

const Services = () => {
  return (
    <section className="bg-gray-600" id="services">
      <div className="container flex flex-wrap mx-auto">
        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                <i className="fas fa-lightbulb"></i>
              </div>
              <h6 className="text-xl font-semibold">Specialty</h6>
              <p className="mt-2 mb-4 text-gray-600">
                We are experts on web and hybrid mobile application development,
                focused on using agile development to create flexible,
                maintainable and robust products so we can pivot fast with your
                business.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                <i className="fas fa-life-ring"></i>
              </div>
              <h6 className="text-xl font-semibold">Support</h6>
              <p className="mt-2 mb-4 text-gray-600">
                We work together with you to analyze requirement, develop your
                online business and build the app that satisfies your needs. We
                can deploy, maintain and monitor your apps to give you a peace
                of mind.
              </p>
            </div>
          </div>
        </div>

        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                <i className="fas fa-award"></i>
              </div>
              <h6 className="text-xl font-semibold">Quality</h6>
              <p className="mt-2 mb-4 text-gray-600">
                The seasoned industry professionals at NuZeta pride ourselves in
                writing clean, maintainable code. We fully test our product and
                build robust and solid web and mobile apps.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
